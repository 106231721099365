
import AppConfig          from '@/shared/services/app_config'
import Records            from '@/shared/services/records'
import Session            from '@/shared/services/session'
import EventBus           from '@/shared/services/event_bus'
import AbilityService     from '@/shared/services/ability_service'
import RecordLoader       from '@/shared/services/record_loader'
import { capitalize, take, keys, every, orderBy, debounce } from 'lodash'
import { subDays, addDays, subWeeks, subMonths } from 'date-fns'

export default
  data: ->
    threads: []
    loader: null

  created: ->
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  mounted: ->
    EventBus.$emit('content-title-visible', false)
    EventBus.$emit 'currentComponent',
      titleKey: @titleKey
      page: 'threadsPage'
      search:
        placeholder: @$t('navbar.search_all_threads')
    @init()

  watch:
    '$route.query': 'refresh'

  methods:
    init: ->
      @loader = new RecordLoader
        collection: 'discussions'
        path: 'direct'
        params:
          exclude_types: 'poll group outcome'
      @loader.fetchRecords()

      @watchRecords
        key: 'dashboard'
        collections: ['discussions']
        query: =>
          @threads = Records.discussions.collection.chain().find(groupId: null).simplesort('lastActivityAt', true).data()

